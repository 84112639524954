
import {
    defineComponent,
    ref,
    watch,
    computed,
    onMounted,
    nextTick,
} from 'vue';
import { useStore } from 'vuex';
import moment, { Moment } from 'moment';
import { getTypeClass } from '@/API/salesReporting/claimCheckingReport';
import { searchVehicle } from '@/API/checking/checkingManagement';
import { findBrandByBu, checkingEstimation } from '@/API/salesReporting';
import { getOfferType } from '@/API/claim/delaerClaim/delaer';
import { qmOptions } from '@/views/SalesReporting/type';
import { message, TreeSelect } from 'ant-design-vue';
import BuSelector from '@/views/SalesReporting/components/BuSelector.vue';
import DM from '@/views/SalesReporting/components/downloadModal.vue';
import downloadFile from '@/utils/claim/downloadFile';

const SHOW_PARENT = TreeSelect.SHOW_PARENT;

export default defineComponent({
    components: {
        DM,
        BuSelector,
        VNodes: (_, { attrs }) => {
            return attrs.vnodes;
        },
    },
    setup() {
        const searchCurrent = ref(false);
        const spaceSize = 6;
        // bu
        const bu = ref('');
        let buCopy = '';

        // brand
        const brand = ref<string | undefined>(undefined);
        const brandList = ref<any[]>([]);
        const store = useStore();
        const buNameIdMap = computed(() => {
            const buLst = store.state.user.buList.reduce(
                (maps: any, bu: any) => {
                    return { ...maps, [bu.nameCn]: bu.id };
                },
                {}
            );
            return buLst;
        });
        const getBrandList = (buId: number) => {
            findBrandByBu({ buIds: [buId] }).then((res: any) => {
                if (res[0].brandList.length > 0) {
                    brandList.value = [
                        ...res[0].brandList,
                        { id: 0, brandNameEn: 'All' },
                    ];
                    brand.value = brandList.value[0].brandNameEn;
                }
            });
        };
        watch(bu, (newVal: any) => {
            getBrandList(buNameIdMap.value[newVal]);
        });
        // Payout Round
        const payoutRound = ref<Moment[]>([]);
        const isPayoutRoundOpen = ref(false);
        let clickTimes = 0;
        const payoutRoundPanelChange = (val: Moment[]) => {
            payoutRound.value = val;
            clickTimes++;
            if (clickTimes === 2) {
                isPayoutRoundOpen.value = false;
                clickTimes = 0;
            }
        };
        const payoutRoundChange = (val: Moment[]) => {
            payoutRound.value = val;
        };
        const payoutRoundOpenChange = (status: boolean) => {
            isPayoutRoundOpen.value = status;
        };

        // type class
        const typeClass = ref<string[]>();
        const typeClassList = ref([]);
        getTypeClass().then((res: any) => {
            typeClassList.value = res;
        });

        // make
        const make = ref<string | undefined>(undefined);
        const makeList = ref<any[]>([]);
        const initPage = ref(false);
        const getMakeList = (brandArr: string[]) => {
            const params = {
                brand: brandArr,
                make: [],
                modelName: [],
                nst: [],
                typeClass: [],
                powerTrain: [],
            };
            searchVehicle(params).then((res: any) => {
                if (res.make && res.make.length > 0) {
                    makeList.value = res.make;
                    make.value = res.make[0];
                    initPage.value = true;
                } else {
                    makeList.value = [];
                }
            });
        };
        watch(brand, (newVal: any) => {
            if (!newVal) {
                make.value = undefined;
                return;
            }
            let brandArr: string[];
            if (newVal === 'All') {
                brandArr = brandList.value.map((bd: any) => bd.brandNameEn);
                brandArr.pop();
            } else {
                brandArr = [newVal];
            }
            getMakeList(brandArr);
        });

        // year
        const years = ref<Moment[]>([]);
        const handleYearChange = (val: Moment[]) => {
            years.value = val;
        };
        const handleYearPanelChange = (val: Moment[], mode: string[]) => {
            const start = val[0].format('YYYY');
            const end = val[1].format('YYYY');
            if (start < end) {
                years.value = val;
            } else {
                years.value = [val[1], val[0]];
            }
        };

        // Program Catogroy
        const programCategory = ref<string[] | undefined>(undefined);
        const programCategoryList = ref([]);
        getOfferType().then((res: any): void => {
            programCategoryList.value = res.incentiveCategory.reduce(
                (arr: any, ele: any) => {
                    return [...arr, ...ele.offerType];
                },
                []
            );
        });

        // close or not
        const closedProgram = ref<string | undefined>(undefined);
        const closedProgramList = ref([
            {
                id: '1',
                name: 'Y',
            },
            {
                id: '0',
                name: 'N',
            },
            {
                id: '2',
                name: 'All',
            },
        ]);

        const defalutColumns = [
            {
                title: 'Program Code',
                dataIndex: 'programCode',
                align: 'center',
                key: '1',
            },
            {
                title: 'Program Name',
                dataIndex: 'programName',
                align: 'center',
                key: '2',
            },
            {
                title: 'Program Category',
                dataIndex: 'offerType',
                align: 'center',
                key: '3',
            },
            {
                title: 'Make',
                dataIndex: 'make',
                align: 'center',
                key: '4',
            },
            {
                title: 'Approved Budget',
                dataIndex: 'budgetAmount',
                align: 'center',
                key: '5',
            },
            {
                title: 'Already Paid Amount(w/o VAT)',
                dataIndex: 'paidAmountWOVat',
                align: 'center',
                key: '6',
            },
            {
                title: 'Already Paid Amount(VAT)',
                dataIndex: 'paidAmountWVat',
                align: 'center',
                key: '7',
            },
            {
                title: 'Pending Amount',
                dataIndex: 'pendingAmount',
                align: 'center',
                key: '8',
            },
            {
                title: 'SD Best Estimation',
                dataIndex: 'bestEstimation',
                align: 'center',
                key: '9',
            },
            {
                title: 'Close or not',
                dataIndex: 'closeOrNot',
                align: 'center',
                key: '10',
            },
            {
                title: 'Total Payout(w/ VAT)',
                dataIndex: 'totalAmountWVat',
                align: 'center',
                key: '11',
            },
        ];
        // 查询的数据
        const dataSource = ref<any[]>([]);

        const reset = () => {
            bu.value = buCopy;
            brand.value = brandList.value.length ? brandList.value[0].brandNameEn : undefined;
            payoutRound.value = [];
            typeClass.value = [];
            make.value = makeList.value.length ? makeList.value[0] : undefined;
            years.value = [];
            programCategory.value = undefined;
            closedProgram.value = undefined;
        };

        const initValue = (value: string) => {
            bu.value = value;
            buCopy = value;
        };

        const notHaveMake = () => {
            if (!make.value) {
                message.error('Please select make');
                return true;
            }
            return false;
        };

        const genParams = () => {
            return {
                buList: [bu.value],
                brands:
                    !brand.value || brand.value === 'All' ? [] : [brand.value],
                closeOrNot: closedProgram.value,
                make: make.value,
                offerTypes: programCategory.value,
                payoutRoundEnd: payoutRound.value[1]?.format('yyyy-MM'),
                payoutRoundStart: payoutRound.value[0]?.format('yyyy-MM'),
                programYears: years.value.map((year: any) => {
                    return year.format('YYYY');
                }),
                typeClasses: typeClass.value,
            };
        };

        const downloadTableData = [
            // {
            //     file: 'Raw Data',
            //     sign: 2,
            // },
            {
                file: 'Report Data',
                sign: 4,
            },
        ];

        const downloadTypeVisible = ref(false);

        const download = () => {
            downloadTypeVisible.value = true;
        };
        const closeDownloadTypeModal = () => {
            downloadTypeVisible.value = false;
        };

        const handleOk = (selectedData: any) => {
            if (notHaveMake()) return;
            // let downloadType;
            // if (selectedData.length > 1) {
            //     downloadType = ['2', '4'];
            // } else {
            //     downloadType = [selectedData[0].sign];
            // }
            const downloadType = [selectedData[0].sign];
            const downloadParams = {
                ...genParams(),
                fileTypeList: downloadType,
            };
            const params = {
                url: `/rpapi/checking/estimation/export`,
                method: 'post',
                params: downloadParams,
            };
            downloadFile(params, 'application/vnd-excel; char-set=UTF-8').then(
                (res: any) => {
                    downloadTypeVisible.value = false;
                }
            );
        };

        const columns = ref<any[]>([...defalutColumns]);
        const search = () => {
            if (notHaveMake()) return;
            const params = genParams();
            checkingEstimation(params).then((res: any) => {
                const months = res.months;
                let key = 11;
                const dynamicColumns = months.map((month: string) => {
                    key++;
                    return {
                        title: month,
                        dataIndex: month,
                        align: 'center',
                        key: key,
                    };
                });
                columns.value = [...defalutColumns, ...dynamicColumns];
                dataSource.value = res.list;
            });
            searchCurrent.value = true;
        };
        watch(initPage, (newVal) => {
            if (newVal && searchCurrent.value) {
                search();
            }
        });

        // program category 全选
        const isProgramCategorySelectAll = ref(false);
        const programCategorySelectAllOrNot = () => {
            isProgramCategorySelectAll.value = !isProgramCategorySelectAll.value;
            if (isProgramCategorySelectAll.value) {
                const allProgramCategory = programCategoryList.value.map(
                    (pg: any) => {
                        return pg.nameEn;
                    }
                );
                programCategory.value = allProgramCategory;
            } else {
                programCategory.value = [];
            }
        };

        // make 全选
        // const isMakeSelectAll = ref(false);
        // const makeSelectAllOrNot = () => {
        //     isMakeSelectAll.value = !isMakeSelectAll.value;
        //     if (isMakeSelectAll.value) {
        //         make.value = makeList.value;
        //     } else {
        //         make.value = [];
        //     }
        // };

        // type class 全选
        const isTypeClassSelectAll = ref(false);
        const typeClassSelectAllOrNot = () => {
            isTypeClassSelectAll.value = !isTypeClassSelectAll.value;
            if (isTypeClassSelectAll.value) {
                typeClass.value = typeClassList.value;
            } else {
                typeClass.value = [];
            }
        };

        const colHeight = ref(500);
        const calculateHeight = () => {
            const table = document.getElementsByClassName(
                'ant-table-wrapper'
            )[0];
            const clinetHeight = document.body.clientHeight;
            const top = table.getBoundingClientRect().top;
            colHeight.value = clinetHeight - top - 60;
        };

        nextTick(() => {
            calculateHeight();
            console.log('colHeight', colHeight.value);
        });

        onMounted(() => {
            window.addEventListener('resize', calculateHeight);
        });

        return {
            searchCurrent,
            spaceSize,
            bu,
            programCategory,
            programCategoryList,
            brand,
            brandList,
            payoutRound,
            isPayoutRoundOpen,
            payoutRoundPanelChange,
            payoutRoundChange,
            payoutRoundOpenChange,
            typeClass,
            typeClassList,
            make,
            makeList,
            years,
            handleYearChange,
            handleYearPanelChange,
            closedProgram,
            closedProgramList,
            columns,
            colHeight,
            dataSource,
            reset,
            download,
            initValue,
            qmOptions,
            SHOW_PARENT,
            downloadTypeVisible,
            downloadTableData,
            closeDownloadTypeModal,
            handleOk,
            search,
            isProgramCategorySelectAll,
            programCategorySelectAllOrNot,
            // isMakeSelectAll,
            // makeSelectAllOrNot,
            isTypeClassSelectAll,
            typeClassSelectAllOrNot,
        };
    },
});
